@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,400i,700&display=swap");

body {
  font-family: "Josefin Sans", sans-serif !important;
}

#nav2 a {
  color: white;
}

.article-content .ques {
  font-size: 1.2em;
  margin-top: 25px;
}
/* ListShack Support in top Nav text */
.lss_nav_text:hover {
  color: #000000e6 !important;
  text-decoration: none !important;
}
#HW_badge_cont {
  display: inline-block !important;
}

/* Big geo search buttons on /search */

#geoSType .col-6 {
  padding-bottom: 15px;
}

#geoSType .col-6:nth-of-type(even) {
  padding-left: 7px;
}
#geoSType .col-6:nth-of-type(odd) {
  padding-right: 7px;
}

#geoSType .col-6 button {
  height: 130px;
}

/* Support center */

.help-content img {
  max-width: 600px;
  margin: 0 auto;
  border: solid 1px lightgray;
  border-radius: 4px;
  margin: 20px;
  display: inline-block;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* Usage page */
table.usageTable {
  max-width: 500px;
}

table.usageTable tr td p {
  margin-bottom: 0px;
}

table.usageTable tr td:nth-child(2) {
  font-size: 1.5em;
  text-align: right;
  vertical-align: middle;
}

/* Sidebar */
div.sideBar a.nav-link {
  color: #00000080;
}

div.sideBar a.nav-link.sub-nav {
  color: #00000080;
  font-size: 0.9em;
}

div.sideBar a.nav-link:hover {
  color: #000000b3;
}

div.sideBar a.nav-link.activeSidebar {
  color: #000000b3;
  font-size: 1.1em;
}

/* Search again filters */

#saForm {
  background-color: white;
}

#saForm h5 {
  margin-top: 50px;
}

#saForm .form-group {
  margin-bottom: 50px;
}

#saForm label.form-label:not(.col-form-label) {
  font-size: 1.1em;
}

/* Compare Plans Pricing Page */

#pricing table tbody tr td {
  font-size: 1.2em;
}

#pricing table thead tr th:first-of-type,
#pricing table tbody tr td:first-of-type {
  text-align: left;
  font-size: 1em;
}

#pricing table thead tr th,
#pricing table tbody tr td {
  text-align: center;
}

#pricing table thead tr th .planName {
  font-size: 1.1em;
}

#pricing table thead tr th .price {
  font-size: 2.1em;
  font-weight: 300;
}

#pricing table thead tr th .period {
  font-size: 0.8em;
  color: #bbbbbb;
  font-weight: 400;
}

#pricing .table-responsive {
  padding: 20px;
}

#ccpa_disclosures tr td:first-of-type {
  max-width: 400px;
}

.map-user-nav > .nav-item > a.nav-link {
  color: rgba(0,0,0,.5);
}

.map-user-nav > .nav-item > a.nav-link:hover {
  color: rgba(0,0,0,.7);
}

/* Upload Csv */
.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100px;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.65);
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #ff6e40;
  box-shadow: 0 0 13px 3px #ff6e40;
}

#list-menu.dropdown-toggle::after {
  display: none;
}

/* Freeor styles */
body #freeor {
  font-family: 'Times', serif;
}

#freeor.container-fluid, #freeor #viewCollection, #freeor #lessonsView {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

footer .container-fluid .col {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.lesson-active, .border-gold {
  border: solid 2px #C5B384 !important;
}

.box-shadow {
  box-shadow: 2px 2px 5px #a4a4a4;
}

.home-img-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 20vh;
}

.home-img-row.center {
  max-height: 25vh;
}

.home-img-item {
  flex-grow: 1 !important;
  flex-basis: 37.5%;
}

.home-img-item:nth-of-type(2) {
  flex-grow: 1 !important;
  flex-basis: 25%;
}
.bl {
  font-size: 1.3em
}

.quizOpt {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}

.questionTile {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.wmde-markdown {
  font-family: inherit !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}
